<template>
  <div id="custom-modal" class="custom-modal modal-fullscreen">
    <div
      id="custom-modal-body"
      class="custom-modal-body shadow position-relative"
    >
      <div
        class="
          p-3
          bg-primary
          lead
          text-center
          fw-medium
          text-light
          position-relative
        "
      >
        <button
          class="btn py-0 px-4 h-100 position-absolute"
          style="left: 0; top: 0"
          @click="deleteClass()"
          v-if="cls"
        >
          <i class="fa fa-trash-alt text-danger" />
        </button>
        {{ cls ? "Edit" : "Add" }} class
        <button
          class="btn py-0 px-4 h-100 position-absolute"
          style="right: 0; top: 0"
          @click="closePreview()"
        >
          <i class="fa fa-times text-light" />
        </button>
      </div>
      <div class="p-4">
        <div class="row row-cols-1 row-cols-md-2">
          <div class="col">
            <div class="mb-5 mb-md-3 position-relative">
              <label for="poster" class="form-label">Poster</label>
              <div
                class="ratio ratio-1x1 rounded shadow-sm border mx-auto"
                style="width: 220px"
              >
                <button
                  class="
                    btn
                    bg-light
                    d-flex
                    flex-column
                    justify-content-center
                    align-items-center
                    p-0
                  "
                  @click="selectPoster()"
                >
                  <template v-if="models.poster || posterPreview">
                    <img
                      v-if="posterPreview"
                      class="image-fit mh-100"
                      :src="posterPreview"
                    />
                    <img
                      v-else-if="models.poster"
                      class="image-fit mh-100"
                      :src="Helper.formatMediaUrl(models.poster)"
                    />
                    <button
                      class="
                        btn btn-link
                        text-danger text-decoration-none
                        position-absolute
                      "
                      style="bottom: -35px"
                      @click.stop="clearPreview()"
                    >
                      <i class="fas fa-times me-2" />Remove
                    </button>
                  </template>
                  <template v-else>
                    <i class="fas fa-plus mb-2" />
                    <small class="font-monospace text-center lh-sm">
                      Add poster image
                    </small>
                  </template>
                </button>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="mb-3">
              <label for="name" class="form-label">
                Name<sup class="text-danger">*</sup>
              </label>
              <textarea
                type="text"
                class="form-control no-resize"
                id="name"
                rows="3"
                v-model="models.name"
              ></textarea>
            </div>
            <div class="mb-3">
              <label for="note" class="form-label">Note</label>
              <textarea
                type="text"
                class="form-control no-resize"
                id="note"
                rows="3"
                v-model="models.note"
              ></textarea>
            </div>
            <div class="row g-3 mb-3">
              <div class="col">
                <label for="price" class="form-label">
                  Price<sup class="text-danger">*</sup>
                </label>
                <div class="input-group">
                  <span class="input-group-text">RM</span>
                  <input
                    type="text"
                    class="form-control"
                    id="price"
                    @input="models.price = models.price.replace(/[^\d.]/g, '')"
                    v-model="models.price"
                  />
                </div>
              </div>
              <div class="col">
                <label for="link" class="form-label">OnPay link</label>
                <input
                  type="text"
                  class="form-control"
                  id="link"
                  v-model="models.link"
                />
              </div>
            </div>
            <div class="row g-3 mb-3">
              <div class="col">
                <label for="label" class="form-label">
                  Level<sup class="text-danger">*</sup>
                </label>
                <select class="form-select" v-model="models.level">
                  <option value="Beginner">Beginner</option>
                  <option value="Intermediate">Intermediate</option>
                  <option value="Advance">Advance</option>
                </select>
              </div>
              <div class="col">
                <label for="status" class="form-label">
                  Status<sup class="text-danger">*</sup>
                </label>
                <select class="form-select" v-model="models.status">
                  <option value="Draft">Draft</option>
                  <option value="Published">Published</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <hr class="my-4" />
        <div class="d-flex justify-content-between align-items-center">
          <button class="btn btn-light shadow" @click="closePreview()">
            Cancel
          </button>
          <button class="btn btn-primary" @click="submitChange()">
            <i class="fas fa-save me-2" />
            Submit
          </button>
        </div>
        <template v-if="cls">
          <hr class="my-4" />
          <div
            class="mb-3 py-2 d-flex justify-content-between align-items-center"
          >
            <h5 class="mb-0">
              Students
              <span
                class="badge bg-light border text-dark fw-normal py-2 px-3 ms-2"
              >
                {{ cls.dependants ? cls.dependants.length : 0 }}
              </span>
            </h5>
            <button
              class="btn btn-sm btn-primary px-3"
              @click="showSelectStudent = true"
            >
              <i class="fas fa-plus me-2" />
              Add student
            </button>
          </div>
          <div
            class="
              table-responsive
              rounded
              shadow-sm
              rounded
              border border-light
            "
          >
            <table class="table lh-sm table-admin w-100">
              <thead>
                <tr>
                  <th class="pe-0">#</th>
                  <th>Name</th>
                  <th class="text-center">MYKID/NRIC</th>
                  <th class="text-center">Age</th>
                  <th>Parent</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(student, index) in classStudents"
                  :key="student._id"
                  :ref="`student_${student._id}`"
                >
                  <td style="width: 1%" class="pe-0">{{ index + 1 }}</td>
                  <td class="text-nowrap text-truncate">
                    <i
                      class="fas fa-circle me-2"
                      :class="
                        student.status == 'Active'
                          ? 'text-success'
                          : 'text-danger'
                      "
                    />
                    {{ student.name }}
                  </td>
                  <td class="text-nowrap text-truncate" style="width: 1%">
                    {{ student.id_no }}
                  </td>
                  <td
                    class="text-nowrap text-truncate text-center"
                    style="width: 1%"
                  >
                    {{ Helper.getAge(student.date_of_birth) }}
                  </td>
                  <td class="text-nowrap text-truncate">
                    <small class="text-muted d-block">
                      {{ student.user ? student.user.email : "\&nbsp;" }}
                    </small>
                    {{ student.user ? student.user.name : "\&nbsp;" }}
                  </td>
                  <td class="text-nowrap text-center" style="width: 1%">
                    <button class="btn btn-sm" @click="removeStudent(student)">
                      <i class="fas fa-minus-circle text-danger" />
                    </button>
                  </td>
                </tr>
                <tr v-if="classStudents.length < 1">
                  <td colspan="6" class="text-center fst-italic text-muted">
                    No student found
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
      </div>
      <loading-spinner class="text-light position-absolute" v-if="isLoading" />
      <select-student
        @close="closeSelectStudent"
        v-if="showSelectStudent"
        :disable="classStudents"
      />
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import LoadingSpinner from "@/components/LoadingSpinner";
import SelectStudent from "@/components/Modals/SelectStudent";

export default {
  props: ["cls"],
  components: {
    LoadingSpinner,
    SelectStudent,
  },
  data() {
    return {
      isLoading: false,
      showSelectStudent: false,
      classStudents: [],
      posterPreview: null,
      posterInput: null,
      models: {
        name: null,
        poster: null,
        price: 0,
        note: null,
        link: null,
        level: "Beginner",
        status: "Draft",
      },
    };
  },
  computed: {
    formInvalid() {
      if (
        !this.models.name ||
        this.models.name.length < 4 ||
        typeof this.models.price == "undefined" ||
        !this.models.level ||
        !this.models.status
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    async closeSelectStudent(e) {
      this.showSelectStudent = false;

      if (e) {
        this.isLoading = true;

        try {
          this.classStudents.push(e);

          const { data } = await this.API.put(`classes/${this.cls._id}`, {
            dependants: this.classStudents,
          });

          this.$emit("update", data);
        } catch (error) {
        } finally {
          this.isLoading = false;
        }
      }
    },
    removeStudent(student) {
      Swal.fire({
        title:
          "<h5 class='mb-0'>Remove this student from class' booking list?</h5>",
        icon: "question",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let classStudents = this.classStudents.filter(
            (u) => u._id != student._id
          );

          this.isLoading = true;

          try {
            const { data } = await this.API.put(`classes/${this.cls._id}`, {
              dependants: classStudents,
            });

            this.classStudents = classStudents;

            this.$emit("update", data);
          } catch (error) {
          } finally {
            this.isLoading = false;
          }
        }
      });
    },
    clearPreview() {
      this.posterInput = null;

      this.posterPreview = null;

      this.models.poster = null;
    },
    posterChanged(e) {
      let file = e.target.files[0];

      this.posterInput = file;

      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = (readerEvent) => {
        this.posterPreview = readerEvent.target.result;
      };
    },
    selectPoster() {
      let input = document.createElement("input");

      input.type = "file";

      input.accept = "image/*";

      input.onchange = this.posterChanged;

      input.click();
    },
    deleteClass() {
      Swal.fire({
        title: "<h5 class='mb-0'>Delete this class?</h5>",
        icon: "question",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Confirm",
        customClass: {
          confirmButton: "bg-danger",
        },
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.isLoading = true;

            return this.API.put(`classes/${this.cls._id}`, {
              status: "Deleted",
            })
              .catch((error) => {})
              .finally(() => {
                Swal.fire("Class deleted", "", "success");

                this.$emit("delete", this.cls);
              });
          }
        })
        .finally(() => {
          setTimeout(() => {
            this.isLoading = false;
          }, 3000);
        });
    },
    closePreview(e) {
      this.$nextTick(() => {
        this.$emit("close", e);
      });
    },
    async submitChange() {
      if (this.formInvalid) {
        Swal.fire("", "Invalid form submitted", "error");
      } else {
        this.isLoading = true;

        let method = "post";

        let url = "classes";

        let body = {};

        try {
          if (this.cls) {
            if (this.posterPreview) {
              let fileModels = {
                ref: "class",
                refId: this.cls._id,
                field: "poster",
                files: this.posterInput,
              };

              const formData = new FormData();

              Object.keys(fileModels).forEach((key) => {
                if (key != "files") {
                  formData.append(key, fileModels[key]);
                } else {
                  formData.append(
                    "files",
                    fileModels.files,
                    fileModels.files.name
                  );
                }
              });

              const { data } = await this.API.postForm("upload", formData);

              this.models.poster = data;
            }

            method = "put";

            url += `/${this.cls._id}`;

            body = { ...this.models };

            if (body.poster) {
              body.poster = body.poster._id;
            }
          } else {
            if (this.posterPreview) {
              const formData = new FormData();

              formData.append("files", this.posterInput, this.posterInput.name);

              const { data } = await this.API.postForm("upload", formData);

              this.models.poster = data;
            }

            body = { ...this.models };
          }

          const { data } = await this.API[method](url, body);

          Swal.fire(
            `Class ${this.cls ? "updated" : "added"}`,
            "",
            "success"
          ).then(() => {
            if (this.cls) {
              this.closePreview(data);
            } else {
              this.$emit("add", data);
            }
          });
        } catch (err) {
          Swal.fire(
            "Opps. An error occured",
            JSON.stringify(err.response.data),
            "error"
          );
        } finally {
          this.isLoading = false;
        }
      }
    },
    getStudents() {
      this.isLoading = true;

      this.API.get(`dependants?_limit=-1&classes=${this.cls._id}`)
        .then((retVal) => {
          this.classStudents = retVal.data;
        })
        .catch((err) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    document.getElementsByTagName("body")[0].classList.add("overflow-hidden");

    if (this.cls) {
      for (const key in this.models) {
        if (Object.hasOwnProperty.call(this.models, key)) {
          this.models[key] = this.cls[key];
        }
      }

      this.getStudents();
    }
  },
  beforeDestroy() {
    this.closePreview();

    document
      .getElementsByTagName("body")[0]
      .classList.remove("overflow-hidden");
  },
};
</script>